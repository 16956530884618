var fingerprint
var sessionId
var limit = 0

// set the relative path to image folder here
var imgPath = 'images/spomin/'
var interval
var passed = 0
var turns
var dot
var noDots
var dotColors = ['#f8ecd2', '#fedada', '#d7e0ef', '#d8e1ee']
window.addEventListener('load', function () {


	var quizModule = document.querySelectorAll('.quiz')[0]
	

	quizModule.innerHTML = '\
		<div class="timer">00:00:00</div>\
		<div class="start">Začni</div>\
		<div class="turns">Število klikov\
			<div class="turn-count">0</div>\
		</div>\
		<div class="overlay"></div>\
		<div class="overlay-lock"></div>\
		<div class="cards inactive"></div>\
		<p class="info-text">Če ste našli enaki sliki, bosta kartici ostali obrnjeni. Če sliki nista enaki, poglejte, kaj se skriva pod drugima dvema karticama.</p>\
	'
	fingerprint
	sessionId
	interval
	passed = 0
	turns = document.querySelectorAll('.turn-count')[0]
	startQuiz()
}, false)

function hasClass(element, cls) {
	return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1
}

function addClass(element, cls) {
	if (element.classList) {
		element.classList.add(cls)
	} else {
	  	element.className += ' ' + cls
	}

}
function removeClass(element, cls) {
	if (element.classList)
		element.classList.remove(cls);
	else
		element.className = element.className.replace(new RegExp('(^|\\b)' + cls.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}
function startTime(el, start, overlay) {

	start.innerHTML = 'ponastavi'
	overlay.style.display = 'none'
	passed = 0
	turns.innerHTML = 0
	clearInterval(interval)
	clickCount = 0

	interval = setInterval(function () {
		passed++
		timePassed(el, passed * 10)
	}, 10)
}

function timePassed(el, passed) {

	el.innerHTML = msToTime(passed)
}

function msToTime(s) {
	var ms = s % 1000
	s = (s - ms) / 1000
	var secs = s % 60
	s = (s - secs) / 60
	var mins = s % 60
	var hrs = (s - mins) / 60

	return pad(mins, 2) + ':' + pad(secs,2) + ':' + pad(ms/10,2) 
}
function makeId(lim) {
	var text = ""
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

	for( var i=0; i < lim; i++ ) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}
	return text
}

function pad(num, size) {
	var s = num+""
	while (s.length < size) s = "0" + s
	return s
}

function endTime() {
	clearInterval(interval)
	var timer = document.querySelectorAll('.timer')[0]
	var turncount = document.querySelectorAll('.turn-count')[0]
	var endData = {
		'time': timer.innerHTML,
		'session': sessionId,
		'fingerprint': fingerprint,
		'turns': turncount.innerHTML,
		'limit' : limit
	}

	// ajaxData('index.html', endData)
	var overlay = document.querySelectorAll('.overlay')[0]
	var overlayLock = document.querySelectorAll('.overlay-lock')[0]
	overlayLock.style.display = 'block'
	overlayLock.style.backgroundColor = 'rgba(0,0,0,0.5)'
	overlayLock.style.zIndex = '10'
	overlayLock.innerHTML = '\
	<div class="infobox scale">\
		<div class="close-spomin"></div>\
		<img src="images/spomin.png" alt="">\
		<h2>Čestitamo!</h2>\
		<h3>Uspešno ste odkrili vseh '+endData['limit']+' parov.</h3>\
		<h4>Porabljenih potez: ' +endData['turns'] + '</h4>\
		<p>Delite svoj rezultat na družbenih omrežjih</p>\
		<div class="share"><a href="#" class="fb"></a><a href="#" class="tw"></a></div>\
		<p>#aktivnivsi</p>\
		<div class="btn start">Nova igra</div>\
	</div>'
  var share = document.querySelectorAll('.fb')[0]
	share.addEventListener("click", function(event){
    event.preventDefault()
		sharer(endData['limit'], endData['turns'], endData['time'])
	})
  var twitterBtn = document.querySelectorAll('.tw')[0]
    twitterBtn.addEventListener('click', function(event) {
      event.preventDefault()
      twitterIt(endData['limit'], endData['turns'], endData['time'])
  })
	var infobox = document.querySelectorAll('.infobox')[0]
	setTimeout(function () {
		removeClass(infobox, 'scale')
	}, 500)

	var close = document.querySelectorAll('.close-spomin')[0]
	close.addEventListener('click', function () {
		close.parentNode.parentNode.removeChild(close.parentNode)
		overlayLock.style.display = 'none'
		overlayLock.style.backgroundColor = 'rgba(0,0,0,0.0)'
		overlayLock.style.zIndex = '2'
	})

	var infoboxStart = document.querySelectorAll('.infobox .start')[0]
	infoboxStart.addEventListener('click', function () {
		startQuiz()
		overlayLock.style.display = 'none'
		overlayLock.innerHTML = ''
		overlayLock.style.display = 'none'
		overlayLock.style.backgroundColor = 'rgba(0,0,0,0.0)'
		overlayLock.style.zIndex = '2'
	})
}


function ajaxData(url, data) {

	var xmlhttp = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
	xmlhttp.onreadystatechange = function () {
		if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
		// var response = JSON.parse(xmlhttp.responseText);
		// do something with ajax response
		console.log(xmlhttp.responseText)

		}
	}
	xmlhttp.open("POST", url, true);
	xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
	xmlhttp.send('data=' + JSON.stringify(data));
}

function shuffle(a) {
	var j, x, i
	for (i = a.length; i; i--) {
		j = Math.floor(Math.random() * i)
		x = a[i - 1]
		a[i - 1] = a[j]
		a[j] = x
	}
}

function sharer(limit, turns, time) {
	
	var page_url = window.location.href
	page_url = 'http://www.enakostspolov.si/spomin'
	var title = limit + ' parov sem odkril v ' + turns + ' potezah!';
	var im_url = 'http://www.enakostspolov.si/images/MDDSZ_OG_Spomin_1.jpg';
	var facebook_appID = '337876396370054'
	url = "https://www.facebook.com/dialog/feed?app_id="+ facebook_appID +    "&link=" + encodeURIComponent(page_url)+ 
	"&name=" + encodeURIComponent(title) + 
	"&caption=" + encodeURIComponent('Čas reševanja: '+time) + 
	"&description=" + encodeURIComponent('Preverite se v igri Spomin in odkrijte poklicne pare: če je moški kuhar, je ženska kuharica ... Kako hitro vam uspe odkriti vse?') + 
	"&picture=" + encodeURIComponent(im_url) +
	"&redirect_uri=https://www.facebook.com";
	window.open(url);

}

function twitterIt (limit, turns, time) {

  var twitterText = ''
  var twitterString = ''
  var twitterUrl = window.location.href
  twitterText = limit + ' parov sem odkril v ' + turns + ' potezah! Čas reševanja: ' + time;
  twitterString = 'https://twitter.com/share?text=' +
  encodeURIComponent(twitterText) +
  '.' +
  '&url=' +
  twitterUrl;
  window.open(twitterString);

}

function startQuiz () {


	var numberOfCards = 12
	var first = []
	var ids = []
	var availableCards = []
	var second = []
	var dir = imgPath+'/cards/'
	var ext = '.jpg'
	for (var i = 0; i < numberOfCards; i++) {
		availableCards[i] = {
			"id": makeId(10),
			"cards": [
				{
					"link" : pad(1+(i*2),2),
					"cardId" : makeId(10)
				},
				{
					"link" : pad(2+(i*2),2),
					"cardId" : makeId(10)
				}
			]	
			
		}
	}

	shuffle(availableCards)
	var screenWidth = window.innerWidth
	noDots = 6
	limit = 12
	if (screenWidth < 900) {
		noDots = 4
		limit = 8
	}
	if (screenWidth < 600) {
		noDots = 3
		limit = 6
	}
	
	var sortedCards = []
	for(var a = 0; a < limit; a++) { 
		sortedCards.push(availableCards[a]['cards'])
	}
	shuffle(sortedCards)
	var html = ''
	var finalSort = []

	for(var a = 0; a < sortedCards.length; a++) {
		for(var u = 0; u < sortedCards[a].length; u++) {

		finalSort.push(sortedCards[a][u])
		}
	}
	shuffle(finalSort)

	for(var u = 0; u < finalSort.length; u++) {
		c = Math.floor(Math.random() * dotColors.length)
		dot = '<div class="dot" style="background-color: '+dotColors[c]+'"></div>'
		if((u+1) % noDots === 0 || u > (limit + noDots - 1)) {
			dot = ''
		}
		html += '\
		<div class="card-container"><div class="card" id="'+finalSort[u]['cardId']+'">\
			<div class="sign"></div>\
			<div class="placer">\
				<img src="'+imgPath+'placer.png">\
			</div>\
		</div>'+ dot+ '\
		</div>'
	}
	html += '<div class="clear"></div>'

	var timer = document.querySelectorAll('.timer')[0]
	var start = document.querySelectorAll('.start')[0]
	var cardsContainer = document.querySelectorAll('.cards')[0]
	var pairCount = 0
	var pairs = []
	var successCount = 0
	var clickCount = 0
	var prev = ''
	var cardContainer = document.querySelectorAll('.cards')[0]
	cardContainer.innerHTML = html
	var image = document.querySelectorAll('.placer img')[0];
	var quizModule = document.querySelectorAll('.quiz')[0];
	image.addEventListener("load", function() {
	    quizModule.style.opacity = 1
	});
	var cards = document.querySelectorAll('.card')
	var overlay = document.querySelectorAll('.overlay')[0]
	var overlayLock = document.querySelectorAll('.overlay-lock')[0]
	start.addEventListener('click', function () {
		addClass(cardsContainer, 'inactive');
		setTimeout(function () {
			removeClass(cardsContainer, 'inactive');
		}, 1000)
		if(start.innerHTML === 'ponastavi') {
			startQuiz()
		}
		fingerprint = new Fingerprint().get()
		sessionId = makeId(10)
		startTime(timer, start, overlay)
	})
	for (var i = 0; i < cards.length; i++) {
		cards[i].addEventListener('click', function () {
			if(hasClass(this,'active')) {
				return false
			}
			pairCount++
			clickCount++
			turns.innerHTML = clickCount
			var id = this.getAttribute('id')
			pairs.push(id)
			for(var m = 0; m < availableCards.length; m++) {
				for(var a = 0; a < availableCards[m]['cards'].length; a++) {
					if(availableCards[m]['cards'][a]['cardId'] === id) {
						addClass(this, 'active')
						this.style.backgroundImage = 'url('+dir+availableCards[m]['cards'][a]['link']+ext+')'
						this.style.backgroundColor = 'white'
						this.querySelectorAll('.sign')[0].style.display = 'none'
						var img = availableCards[m]['id']
					}
				}
			}

			if(prev === img) {
				for (var i = 0; i < pairs.length; i++) {
					addClass(document.getElementById(pairs[i]), 'matched')

				}
				successCount++
				if(successCount ===limit) {
					endTime()
				}
			}
			prev = img
			if(pairCount === 2 && successCount != limit) {
				overlay.style.display = 'block'
				pairs = []
				pairCount = 0
				prev = 0
				setTimeout(function () {

					for (var i = 0; i < cards.length; i++) {
						if(!hasClass(cards[i], 'matched')) {
							removeClass(cards[i], 'active')
							cards[i].style.backgroundImage = ''
							cards[i].style.backgroundColor = '#88b1b7'
							cards[i].querySelectorAll('.sign')[0].style.display = 'block'
						}	
					}
					overlay.style.display = 'none'
				}, 1500)
				
			}
			
		})
	}
}

(function (name, context, definition) {
	if (typeof module !== 'undefined' && module.exports) {
		module.exports = definition()
	} else if (typeof define === 'function' && define.amd) {
		define(definition)
	} else {
		context[name] = definition()
	}
})('Fingerprint', this, function () {
	'use strict'

	var Fingerprint = function (options) {
		var nativeForEach, nativeMap
		nativeForEach = Array.prototype.forEach
		nativeMap = Array.prototype.map

		this.each = function (obj, iterator, context) {
			if (obj === null) {
				return
			}
			if (nativeForEach && obj.forEach === nativeForEach) {
				obj.forEach(iterator, context)
			} else if (obj.length === +obj.length) {
				for (var i = 0, l = obj.length; i < l; i++) {
					if (iterator.call(context, obj[i], i, obj) === {}) return
				}
			} else {
				for (var key in obj) {
					if (obj.hasOwnProperty(key)) {
						if (iterator.call(context, obj[key], key, obj) === {}) return
					}
				}
			}
		}

		this.map = function (obj, iterator, context) {
			var results = []
			// Not using strict equality so that this acts as a
			// shortcut to checking for `null` and `undefined`.
			if (obj == null) return results
			if (nativeMap && obj.map === nativeMap) return obj.map(iterator, context)
			this.each(obj, function (value, index, list) {
				results[results.length] = iterator.call(context, value, index, list)
			})
			return results
		}

		if (typeof options == 'object') {
			this.hasher = options.hasher
			this.screen_resolution = options.screen_resolution
			this.screen_orientation = options.screen_orientation
			this.canvas = options.canvas
			this.ie_activex = options.ie_activex
		} else if (typeof options == 'function') {
			this.hasher = options
		}
	}

	Fingerprint.prototype = {
		get: function () {
			var keys = []
			keys.push(navigator.userAgent)
			keys.push(navigator.language)
			keys.push(screen.colorDepth)
			if (this.screen_resolution) {
				var resolution = this.getScreenResolution()
				if (typeof resolution !== 'undefined') { // headless browsers, such as phantomjs
					keys.push(resolution.join('x'))
				}
			}
			keys.push(new Date().getTimezoneOffset())
			keys.push(this.hasSessionStorage())
			keys.push(this.hasLocalStorage())
			keys.push(!!window.indexedDB)
			//body might not be defined at this point or removed programmatically
			if (document.body) {
				keys.push(typeof (document.body.addBehavior))
			} else {
				keys.push(typeof undefined)
			}
			keys.push(typeof (window.openDatabase))
			keys.push(navigator.cpuClass)
			keys.push(navigator.platform)
			keys.push(navigator.doNotTrack)
			keys.push(this.getPluginsString())
			if (this.canvas && this.isCanvasSupported()) {
				keys.push(this.getCanvasFingerprint())
			}
			if (this.hasher) {
				return this.hasher(keys.join('###'), 31)
			} else {
				return this.murmurhash3_32_gc(keys.join('###'), 31)
			}
		},

		murmurhash3_32_gc: function (key, seed) {
			var remainder, bytes, h1, h1b, c1, c2, k1, i

			remainder = key.length & 3 // key.length % 4
			bytes = key.length - remainder
			h1 = seed
			c1 = 0xcc9e2d51
			c2 = 0x1b873593
			i = 0

			while (i < bytes) {
				k1 =
					((key.charCodeAt(i) & 0xff)) |
					((key.charCodeAt(++i) & 0xff) << 8) |
					((key.charCodeAt(++i) & 0xff) << 16) |
					((key.charCodeAt(++i) & 0xff) << 24)
				++i

				k1 = ((((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16))) & 0xffffffff
				k1 = (k1 << 15) | (k1 >>> 17)
				k1 = ((((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16))) & 0xffffffff

				h1 ^= k1
				h1 = (h1 << 13) | (h1 >>> 19)
				h1b = ((((h1 & 0xffff) * 5) + ((((h1 >>> 16) * 5) & 0xffff) << 16))) & 0xffffffff
				h1 = (((h1b & 0xffff) + 0x6b64) + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16))
			}

			k1 = 0

			switch (remainder) {
			case 3:
				k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16
			case 2:
				k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8
			case 1:
				k1 ^= (key.charCodeAt(i) & 0xff)

				k1 = (((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff
				k1 = (k1 << 15) | (k1 >>> 17)
				k1 = (((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff
				h1 ^= k1
			}

			h1 ^= key.length

			h1 ^= h1 >>> 16
			h1 = (((h1 & 0xffff) * 0x85ebca6b) + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff
			h1 ^= h1 >>> 13
			h1 = ((((h1 & 0xffff) * 0xc2b2ae35) + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16))) & 0xffffffff
			h1 ^= h1 >>> 16

			return h1 >>> 0
		},

		// https://bugzilla.mozilla.org/show_bug.cgi?id=781447
		hasLocalStorage: function () {
			try {
				return !!window.localStorage
			} catch (e) {
				return true // SecurityError when referencing it means it exists
			}
		},

		hasSessionStorage: function () {
			try {
				return !!window.sessionStorage
			} catch (e) {
				return true // SecurityError when referencing it means it exists
			}
		},

		isCanvasSupported: function () {
			var elem = document.createElement('canvas')
			return !!(elem.getContext && elem.getContext('2d'))
		},

		isIE: function () {
			if (navigator.appName === 'Microsoft Internet Explorer') {
				return true
			} else if (navigator.appName === 'Netscape' && /Trident/.test(navigator.userAgent)) { // IE 11
				return true
			}
			return false
		},

		getPluginsString: function () {
			if (this.isIE() && this.ie_activex) {
				return this.getIEPluginsString()
			} else {
				return this.getRegularPluginsString()
			}
		},

		getRegularPluginsString: function () {
			return this.map(navigator.plugins, function (p) {
				var mimeTypes = this.map(p, function (mt) {
					return [mt.type, mt.suffixes].join('~')
				}).join(',')
				return [p.name, p.description, mimeTypes].join('::')
			}, this).join(';')
		},

		getIEPluginsString: function () {
			if (window.ActiveXObject) {
				var names = [
					'ShockwaveFlash.ShockwaveFlash', //flash plugin
					'AcroPDF.PDF', // Adobe PDF reader 7+
					'PDF.PdfCtrl', // Adobe PDF reader 6 and earlier, brrr
					'QuickTime.QuickTime', // QuickTime
					// 5 versions of real players
					'rmocx.RealPlayer G2 Control',
					'rmocx.RealPlayer G2 Control.1',
					'RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)',
					'RealVideo.RealVideo(tm) ActiveX Control (32-bit)',
					'RealPlayer',
					'SWCtl.SWCtl', // ShockWave player
					'WMPlayer.OCX', // Windows media player
					'AgControl.AgControl', // Silverlight
					'Skype.Detection']

				// starting to detect plugins in IE
				return this.map(names, function (name) {
					try {
						new ActiveXObject(name)
						return name
					} catch (e) {
						return null
					}
				}).join(';')
			} else {
				return "" // behavior prior version 0.5.0, not breaking backwards compat.
			}
		},

		getScreenResolution: function () {
			var resolution
			if (this.screen_orientation) {
				resolution = (screen.height > screen.width) ? [screen.height, screen.width] : [screen.width, screen.height]
			} else {
				resolution = [screen.height, screen.width]
			}
			return resolution
		},

		getCanvasFingerprint: function () {
			var canvas = document.createElement('canvas')
			var ctx = canvas.getContext('2d')
			var txt = 'http://valve.github.io'
			ctx.textBaseline = "top"
			ctx.font = "14px 'Arial'"
			ctx.textBaseline = "alphabetic"
			ctx.fillStyle = "#f60"
			ctx.fillRect(125, 1, 62, 20)
			ctx.fillStyle = "#069"
			ctx.fillText(txt, 2, 15)
			ctx.fillStyle = "rgba(102, 204, 0, 0.7)"
			ctx.fillText(txt, 4, 17)
			return canvas.toDataURL()
		}
	}
	return Fingerprint
})