/* globals $ */

(function () {
  'use strict'
  $('a[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000)
        return false
      }
    }
  })

  $(document).ready(function () {
    $('.video').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: function (url) {
              var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/)
              if (!m || !m[1]) {
                return null
              }
              return m[1]
            },
            src: '//www.youtube.com/embed/%id%?autoplay=1'
          },
          vimeo: {
            index: 'vimeo.com/',
            id: function (url) {
              var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/)
              if (!m || !m[5]) {
                return null
              }
              return m[5]
            },
            src: '//player.vimeo.com/video/%id%?autoplay=1'
          }
        }
      }
    })

    $('.hamburger').click(function (event) {
      event.preventDefault()
      $('.sidebar').addClass('opened')
    })

    $('.close').click(function (event) {
      event.preventDefault()
      $('.sidebar').removeClass('opened')
    })

    $('.center').slick({
      centerMode: false,
      slidesToShow: 2,
      arrows: false,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1
          }
        }
      ]
    })
  })
})()
