$(function () {
	var question_counter;
	var no_questions = $('.questions h2').length;

	Set_defaults();

	function Set_defaults() {
		question_counter = 1;

		$('.questions').show();
		$('.quiz-points').show();
		$('.quiz-wrap').show();
		$('.results').hide();

		$('.questions #q1').addClass('active');
		$('.questions #q1').show();
		$('.quiz-points #point_q1').addClass('active');
		$('.man_icon').find('.man_height').attr('style', 'height: 0% !important');
		$('.man_icon').find('.man_height').data('value', 0);

		$('.man_icon .icons').html("");

		$('.gr .odstotek').html("");
		$('.gr .graf').attr('style', 'width: 0% !important');
	}

	$('.man_icon').click( function() {
		if ($('.questions h2').is(':animated')) return;

		if (question_counter <= no_questions) {
			$icon = $('.icons-source #icon_q' + question_counter);
			$(this).find('.icons').append($icon.clone()).html();

			man_height = $(this).find('.man_height').data('value');
			man_height = man_height + 1;
			$(this).find('.man_height').data('value', man_height);
			$(this).find('.man_height').attr('style', 'height: ' + (man_height / no_questions * 100) + '% !important');

			question_counter = question_counter + 1;

			// za zadnje vprašanje je malo specifike in se skrije spodaj
			if (question_counter <=	no_questions) {
				$('.questions h2').fadeOut(1000).promise().done(function() {
					$(this).removeClass('active');

					$('.questions #q' + question_counter).fadeIn(1000, function() {
						$(this).addClass('active');
					});
				});
			}
			
			$('.quiz-points span').removeClass('active');
			$('.quiz-points #point_q' + question_counter).addClass('active');
		}

		if (question_counter == no_questions + 1) {
			$('.quiz-points').fadeOut(3000, function() {
				$('.quiz-points').hide();

				jaz_parenthood = $('.jaz .icons > span[data-category="parenthood"]').length;
				jaz_chores = $('.jaz .icons > span[data-category="chores"]').length;
				jaz_career = $('.jaz .icons > span[data-category="career"]').length;
				partner_parenthood = $('.partner .icons > span[data-category="parenthood"]').length;
				partner_chores = $('.partner .icons > span[data-category="chores"]').length;
				partner_career = $('.partner .icons > span[data-category="career"]').length;

				$('#g1 .odstotek').html((jaz_parenthood / (partner_parenthood + jaz_parenthood) * 100).toFixed(0) + "%");
				$('#g2 .odstotek').html((jaz_chores / (partner_chores + jaz_chores) * 100).toFixed(0) + "%");
				$('#g3 .odstotek').html((jaz_career / (partner_career + jaz_career) * 100).toFixed(0) + "%");

				$('#g1 .graf').attr('style', 'width: ' + (jaz_parenthood / (partner_parenthood + jaz_parenthood) * 100).toFixed(0) + '% !important');
				$('#g2 .graf').attr('style', 'width: ' + (jaz_chores / (partner_chores + jaz_chores) * 100).toFixed(0) + '% !important');
				$('#g3 .graf').attr('style', 'width: ' + (jaz_career / (partner_career + jaz_career) * 100).toFixed(0) + '% !important');

				$('#g1 .c100').attr('class', 'c100 big p' + (jaz_parenthood / (partner_parenthood + jaz_parenthood) * 100).toFixed(0) + '');
				$('#g2 .c100').attr('class', 'c100 big p' + (jaz_chores / (partner_chores + jaz_chores) * 100).toFixed(0) + '');
				$('#g3 .c100').attr('class', 'c100 big p' + (jaz_career / (partner_career + jaz_career) * 100).toFixed(0) + '');

				$('.questions h2').removeClass('active');
				$('.questions h2').hide();
				$('.questions').hide();
				$('.quiz-wrap').hide();


				$('.results').fadeIn(2000, function() {
					$('.results').show();
				});
			});
		}
	});

	$('.repeat').click( function() {
		Set_defaults();
	});
});